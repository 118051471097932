<template>
  <b-card header="Edit Role Permissions">
    <permissions-table
      :selected-permissions.sync="selectedPermissions"
      :selected-groups.sync="selectedGroups"
      :available-permissions-grouped="availablePermissionsGrouped"
      @assignPermissions="assignRolePermissions"
      @revokePermissions="revokeRolePermissions"
    />
    <back />
  </b-card>
</template>
<script>
import Back from '@/common/components/common/FormInputs/Back.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import PermissionsTable from '@/common/components/Permissions/PermissionsTable.vue'
import Roles from '@/common/compositions/permissions/roles'
import UserPermissions from '@/common/compositions/permissions/userPermissions'

export default {
  name: 'EditRolePermissions',
  components: {
    Back,
    PermissionsTable,
  },
  data() {
    return {
      availablePermissionsGrouped: [],
      selectedPermissions: [],
      selectedGroups: [],
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { getRolePermissions, assignRolePermissions, revokeRolePermissions } = Roles()
    const { getUserPermissionsInEntity } = UserPermissions()
    return {
      getUserPermissionsInEntity, getRolePermissions, successfulOperationAlert, assignRolePermissions, revokeRolePermissions,
    }
  },
  created() {
    this.getAvailablePermissionsGrouped()
    this.getSelectedRolePermissions()
  },
  methods: {
    getAvailablePermissionsGrouped() {
      return this.getUserPermissionsInEntity(
        this.$store.getters['auth/getUserData'].username,
        '',
        { scopeType: 'system-admin,both' },
      ).then(permissionsGrouped => {
        this.availablePermissionsGrouped = permissionsGrouped
      })
    },
    getSelectedRolePermissions() {
      this.getRolePermissions(this.$route.params.roleId).then(permissionsGroup => {
        const permissionsList = permissionsGroup.map(group => group.permissions).flat()
        this.selectedPermissions = permissionsList.map(permission => permission.name)

        this.selectedGroups = permissionsGroup.map(group => group.groupName)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
